import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { Icon } from '../components';
import clsx from 'clsx';


export default React.memo ( SideButton );

function SideButton ({ className, children, ...rest })
{
	const classes = useStyles();

	return (
		<Button
			disableElevation
			className={ clsx ( classes.button, className ) }
			variant="contained"
			color="inherit"
			{ ...rest }
		>
			{ children || <Icon icon="close" size={ 10 } /> }
		</Button>
	);
}


const useStyles = makeStyles ( theme => ({
	button: {
		width: 26,
		height: 26,
		minWidth: 26,
		padding: 0,
		borderRadius: 0,
		backgroundColor: theme.palette.common.darkGrey,
		color: theme.palette.common.white
	}
}) );
