import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';


export default withStyles ({
	logo: {
		position: 'absolute',
		top: '15vh',
		width: '41%',
		minWidth: 320
	}
})( Logo );

function Logo ({ classes, className, src, alt, ...rest })
{
	return (
		<img
			className={ clsx ( classes.logo, className ) }
			{...{ src, alt }}
			{ ...rest }
		/>
	);
}

Logo.propTypes = {
	className: PropTypes.string,
	src: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired
};
