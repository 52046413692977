import React from 'react';
import TextField from '@material-ui/core/TextField';
import { getFormikFieldErrorProps } from '../utils';


export default React.memo ( FormikTextField );

function FormikTextField ({ form, field, ...rest })
{
	return (
		<TextField
			{ ...getFormikFieldErrorProps ( form, field ) }
			{ ...field }
			{ ...rest }
		/>
	);
}
