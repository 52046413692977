import React, { useRef, useEffect } from 'react';
import { useMapComponent } from '../hooks';


export default React.memo ( MapMarginArea );

function MapMarginArea ({ left, top, right, bottom, height, width, panTo })
{
	const area = useRef ( null ),
		{ map } = useMapComponent();

	useEffect (
		() => {
			if ( !map ) return;

			const center = map.getCenter ({ useMapMargin: true });

			if ( area.current ) area.current.remove();

			area.current = map.margin.addArea (
				JSON.parse ( // remove undefined properties
					JSON.stringify ({ left, top, right, bottom, height, width })
				)
			);

			map.panTo ( center, { useMapMargin: true } );
		},
		[ map, left, top, right, bottom, height, width, panTo ]
	);

	return null;
}
