import React, { useCallback } from 'react';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { Button, IconButton as MuiIconButton } from '@material-ui/core';
import { makeStyles, withStyles, fade } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const stop = e => e.stopPropagation();


export default React.memo ( DateBar );

function DateBar ({ className, isRange = false, nextDisabled = false, prevDisabled = false, children, onClick, onPrev, onNext })
{
	const classes = useStyles(),
		onClickPrev = useStop ( onPrev ),
		onClickNext = useStop ( onNext );

	return (
		<Button
			className={ clsx ( classes.button, isRange && classes.range, className ) }
			component="div"
			color="inherit"
			{...{ onClick }}
		>
			{ !!onPrev && !isRange &&
				<IconButton
					color="inherit"
					size="small"
					onMouseDown={ stop }
					disabled={ prevDisabled }
					onClick={ onClickPrev }
				>
					<ChevronLeft />
				</IconButton>
			}
			<span className={ classes.buttonTitle }>
				{ children }
			</span>
			{ !!onNext && !isRange &&
				<IconButton
					color="inherit"
					size="small"
					onMouseDown={ stop }
					disabled={ nextDisabled }
					onClick={ onClickNext }
				>
					<ChevronRight />
				</IconButton>
			}
		</Button>
	);
}

DateBar.propTypes = {
	className: PropTypes.string,
	isRange: PropTypes.bool,
	nextDisabled: PropTypes.bool,
	prevDisabled: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
	onPrev: PropTypes.func,
	onNext: PropTypes.func,
	children: PropTypes.any.isRequired
};


const useStyles = makeStyles ( theme => ({
	button: {
		minHeight: 30,
		padding: 0,
		backgroundColor: fade (
			theme.palette.common.white,
			0.15
		),
		'&:hover': {
			backgroundColor: fade (
				theme.palette.common.white,
				0.2
			)
		},
		transition: 'background-color ease 300ms'
	},
	range: {
		padding: theme.spacing ( 0, 0.5 )
	},
	buttonTitle: {
		margin: theme.spacing ( 0, 0.5 )
	}
}) );

const IconButton = withStyles ( theme => ({
	root: {
		'&:hover': {
			backgroundColor: fade (
				theme.palette.common.white,
				0.15
			)
		},
		'&:disabled': {
			opacity: 0.3,
			color: theme.palette.common.white
		}
	}
}) )( MuiIconButton );

function useStop ( callback )
{
	return useCallback (
		e => {
			stop ( e );

			if ( callback ) callback ( e );
		},
		[ callback ]
	);
}
