import React from 'react';
import { Menu } from '@material-ui/icons';
import { AppBar, Toolbar, IconButton, makeStyles } from '@material-ui/core';
import { useMainDrawer } from '../hooks';
import PropTypes from 'prop-types';
import clsx from 'clsx';


export default React.memo ( MainHeader );

function MainHeader ({ className, children, ...rest })
{
	const classes = useStyles(),
		{ opened, toggle } = useMainDrawer();

	return (
		<AppBar className={ clsx ( classes.appBar, opened && classes.appBarShift, className ) } position="fixed" { ...rest }>
			<Toolbar className={ classes.toolbar }>
				{ children }
				<IconButton
					className={ clsx ( opened && classes.hidden ) }
					color="inherit"
					edge="end"
					onClick={ toggle }
				>
					<Menu />
				</IconButton>
			</Toolbar>
		</AppBar>
	);
}

MainHeader.propTypes = {
	children: PropTypes.any.isRequired
};


const useStyles = makeStyles ( theme => ({
	appBar: {
		transition: theme.transitions.create ( [ 'margin', 'width' ], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		} )
	},
	appBarShift: {
		width: `calc(100% - ${ theme.drawerWidth }px)`,
		transition: theme.transitions.create ( [ 'margin', 'width' ], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		} ),
		marginRight: theme.drawerWidth
	},
	toolbar: {
		justifyContent: 'space-between',
		color: theme.palette.common.white
	},
	hidden: {
		display: 'none'
	}
}) );
