export function getFormikFieldErrorProps ( form, field )
{
	const { name } = field,
		{ touched, errors } = form,
		helperText = touched[ name ] && errors[ name ];

	return { helperText, error: !!helperText };
}

export function getScrollWidth ()
{
	return Math.max (
		document.body.scrollWidth,
		document.documentElement.scrollWidth,
		document.body.offsetWidth,
		document.documentElement.offsetWidth,
		document.body.clientWidth,
		document.documentElement.clientWidth
	);
}
